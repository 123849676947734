<template>
  <div class="container mt-4">
    <div class="mb-1">
      <b-button
        variant="primary"
        class=""
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="$router.push({ name: 'freelance' })"
      >Go Back</b-button>
    </div>
    <b-overlay
      :show="is_loading"
      rounded="sm"
    >
      <h1>{{ action === 'create' ? 'Add': 'Edit' }} Freelance Details</h1>
      <b-card>
        <b-form>
          <!-- Description Field -->
          <b-form-group
            label="Description"
            label-for="description-textarea"
          >
            <b-form-textarea
              id="description-textarea"
              v-model="form.description"
              placeholder="Enter description"
              rows="3"
            />
          </b-form-group>

          <!-- Pricing Section -->
          <b-form-group
            label="Pricing"
            label-for="pricing"
          >
            <b-row>
              <!-- First input: Pricing Amount -->
              <b-col cols="6">
                <b-form-input
                  v-model="form.pricingAmount"
                  type="number"
                  placeholder="Enter price"
                  :disabled="form.priceOnEnquiry"
                />
              </b-col>

              <!-- Second input: Pricing Unit (Hour/Week/Month) -->
              <b-col cols="6">
                <v-select
                  v-model="form.pricingUnit"
                  :options="['Hour', 'Week', 'Month']"
                  :disabled="form.priceOnEnquiry"
                  placeholder="Select unit"
                />
              </b-col>
            </b-row>

            <!-- Price on Enquiry Checkbox -->
            <b-form-checkbox
              v-model="form.priceOnEnquiry"
              class="mt-2"
            >
              Price on enquiry
            </b-form-checkbox>
          </b-form-group>
          <!-- Add industry -->
          <div class="my-1">
            <label for="">Choose your availability</label>
            <v-select
              v-model="form.availability"
              placeholder="Choose your availability"
              class="w-full"
              :options="all_availabilities"
            />
          </div>
          <!-- Add industry -->
          <div class="my-1">
            <label for="">Choose the industry you specialize in</label>
            <v-select
              v-model="form.industry"
              placeholder="Choose the industry you specialize in"
              class="w-full"
              :options="all_industries"
              label="value"
              :reduce="(value) => value.value"
            />
          </div>

          <!-- Add Portfolio Section -->
          <b-form-group>
            <b-form-checkbox v-model="form.addPortfolio">
              Add Portfolio
            </b-form-checkbox>

            <div v-if="form.addPortfolio">
              <div
                v-for="(portfolio, index) in form.portfolios"
                :key="index"
                class="mt-2"
              >
                <!-- Upload Image -->
                <b-form-file
                  v-model="portfolio.image"
                  accept="image/*"
                  placeholder="Choose portfolio image"
                  class="mb-2"
                  @input="portfolioImage(portfolio.image, index)"
                />
                <!-- Portfolio Description -->
                <b-form-textarea
                  v-model="portfolio.description"
                  placeholder="Enter portfolio description"
                  rows="3"
                />

                <!-- Button to Remove Portfolio -->
                <b-button
                  variant="danger"
                  size="sm"
                  class="mt-1"
                  @click="removePortfolio(index)"
                >
                  Remove Portfolio
                </b-button>
              </div>

              <!-- Add New Portfolio Button -->
              <b-button
                variant="primary"
                size="sm"
                class="mt-2"
                @click="addPortfolio"
              >
                Add Another Portfolio
              </b-button>
            </div>
          </b-form-group>

          <!-- Add Case Study Section -->
          <b-form-group>
            <b-form-checkbox v-model="form.addCaseStudy">
              Add Case Study
            </b-form-checkbox>

            <div v-if="form.addCaseStudy">
              <div
                v-for="(caseStudy, index) in form.caseStudies"
                :key="index"
                class="mt-2"
              >
                <!-- Case Study Name -->
                <b-form-input
                  v-model="caseStudy.title"
                  placeholder="Enter case study name"
                  class="mb-2"
                />

                <!-- Case Study Description -->
                <b-form-textarea
                  v-model="caseStudy.description"
                  placeholder="Enter case study description"
                  rows="3"
                />

                <!-- Button to Remove Case Study -->
                <b-button
                  variant="danger"
                  size="sm"
                  class="mt-1"
                  @click="removeCaseStudy(index)"
                >
                  Remove Case Study
                </b-button>
              </div>

              <!-- Add New Case Study Button -->
              <b-button
                variant="primary"
                size="sm"
                class="mt-2"
                @click="addCaseStudy"
              >
                Add Another Case Study
              </b-button>
            </div>
          </b-form-group>

          <!-- Submit Button -->
          <b-button
            class="float-right"
            type="submit"
            variant="success"
            @click.prevent="submitForm"
          >
            Save Profile
          </b-button>
        </b-form>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { singleUpload } from '@/components/awsS3'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { allIndustries } from '@/constants/datastore'
import { orderBy } from 'lodash'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      form: {
        description: null,
        availability: null,
        pricingAmount: null,
        industry: null,
        pricingUnit: null,
        priceOnEnquiry: false,
        addPortfolio: false,
        portfolios: [],
        addCaseStudy: false,
        caseStudies: [],
      },
      all_industries: [],
      all_availabilities: ['Part-time (30hrs or less per week)', 'Full-time (40hrs or more per week)', 'Not Available'],
      portfolioFiles: null,
      portfolioServer: {},
      currentPortfolioImageKey: null,
      currentPortfolioIndex: null,
      is_loading: null,
      action: 'create',
    }
  },
  watch: {
    currentPortfolioImageKey(newVal, oldVal) {
      this.form.portfolios[this.currentPortfolioIndex].image_id = newVal
    },
  },
  created() {
    const freelancerID = this.$route.params.freelancer_id
    if (freelancerID) {
      this.action = 'edit'
      this.getFreelanceDetails(freelancerID)
    }

    this.all_industries = orderBy(allIndustries, ['value'], ['asc'])
  },
  mounted() {
  },
  methods: {

    addPortfolio() {
      this.form.portfolios.push({
        id: null,
        image: null,
        image_id: null,
        description: null,
      })
    },
    removePortfolio(index) {
      this.form.portfolios.splice(index, 1)
    },
    addCaseStudy() {
      this.form.caseStudies.push({
        id: null,
        title: null,
        description: null,
      })
    },
    removeCaseStudy(index) {
      this.form.caseStudies.splice(index, 1)
    },
    getFreelanceDetails(freelancerID) {
      this.is_loading = true
      this.$http.get(
        `/api/freelancer/user/${freelancerID}`,

      ).then(response => {
        const { success, post } = response.data
        if (success) {
          const freelance_profile = post.FreelanceProfile
          this.form.description = freelance_profile.description
          this.form.availability = freelance_profile.availability
          this.form.pricingAmount = freelance_profile.pricing.split('/')[0]
          this.form.pricingUnit = freelance_profile.pricing.split('/')[1]
          this.form.priceOnEnquiry = freelance_profile.price_on_enquiry
          this.form.portfolios = post.portfolios
          this.form.caseStudies = post.case_studies

          if (post.portfolios && post.portfolios.length > 0) {
            this.form.addPortfolio = true
          }

          if (post.case_studies && post.case_studies.length > 0) {
            this.form.addCaseStudy = true
          }
        }

        this.is_loading = false
      })
        .catch(error => {
          this.$toastDanger('Freelance Profile Update Failed', 'Your freelance profile could not be updated at this moment!')

          this.is_loading = false
        })
    },
    createPost() {
      const freelancerInfo = {
        description: this.form.description,
        availability: this.form.availability,
        pricing: `${this.form.pricingAmount}/${this.form.pricingUnit}`,
        price_on_enquiry: this.form.priceOnEnquiry,
        active: true,
        industry: this.form.industry,
        case_studies: this.form.caseStudies,
        portfolios: this.form.portfolios.map(item => ({ image: item.image_id, description: item.description, id: item.id })),
      }

      this.$http.post('/api/freelancer/create', {
        ...freelancerInfo,
      }).then(response => {
        const { success, freelancer_id } = response.data
        // this.updateConfigInfo()
        this.is_loading = false
        if (success) {
          this.$toastSuccess('Freelance Profile Update', 'Your freelance profile has been updated successfully!')
          this.$router.push({ name: 'freelancer-update', params: { freelancer_id } })
        } else {
          this.$toastDanger('Freelance Profile Update Failed', 'Your freelance profile could not be updated at this moment!')
        }
      })
        .catch(error => {
          this.$toastDanger('Freelance Profile Update Failed', 'Your freelance profile could not be updated at this moment!')

          this.is_loading = false
        })
    },

    updatePost() {
      const freelancerInfo = {
        description: this.form.description,
        availability: this.form.availability,
        pricing: `${this.form.pricingAmount}/${this.form.pricingUnit}`,
        price_on_enquiry: this.form.priceOnEnquiry,
        active: true,
        industry: this.form.industry,
        case_studies: this.form.caseStudies,
        portfolios: this.form.portfolios.map(item => ({ image: item.image_id, description: item.description, id: item.id })),
      }

      const freelancerID = this.$route.params.freelancer_id

      this.$http.put(`/api/freelancer/update/${freelancerID}`, {
        ...freelancerInfo,
      }).then(response => {
        const { success, freelancer_id } = response.data
        // this.updateConfigInfo()
        this.is_loading = false
        if (success) {
          this.$toastSuccess('Freelance Profile Update', 'Your freelance profile has been updated successfully!')
         
        } else {
          this.$toastDanger('Freelance Profile Update Failed', 'Your freelance profile could not be updated at this moment!')
        }
      })
        .catch(error => {
          this.$toastDanger('Freelance Profile Update Failed', 'Your freelance profile could not be updated at this moment!')

          this.is_loading = false
        })
    },
    submitForm() {
      // Handle form submission logic
      this.is_loading = true
      if (this.action === 'create') {
        this.createPost()
      } else if (this.action === 'edit') {
        this.updatePost()
      }

    },
    portfolioImage(file, index) {
      const outerThis = this
      if ('isLoading' in outerThis) {
        outerThis.isLoading = true
      }
      const extension = file.name.split('.').pop().toLowerCase()
      const ID = this.$generateUUID4()
      // (outerThis, id, ref = 'pond-file', folder_path = 'others',
      const folder_path = 'freelance-documents'
      this.currentPortfolioIndex = index
      singleUpload(file, folder_path, `${ID}.${extension}`, null, null, null, null, null, outerThis, index)
    },
  },
}
</script>

<style scoped>
/* Add any necessary custom styles here */
</style>
